<template>
  <div class="message pb-6" :class="{'my': isMy, 'from-property': !isMy && authorType === USER_TYPE_QUEXT}">
    <NoteContent
        v-if="message.type === MESSAGE_TYPE_NOTE"
        :note="message"
        :isMy="isMy"
        :authorType="authorType"
        @clickRetry="$emit('clickRetry')"
        @clickDelete="$emit('clickDelete')"
    />
    <ChatMessageContent
        v-if="message.type === MESSAGE_TYPE_SIMPLE"
        :message="message"
        :isMy="isMy"
        :authorType="authorType"
        @clickRetry="$emit('clickRetry')"
        @clickDelete="$emit('clickDelete')"
    />
    <HistoryMessageContent v-if="message.type === 'history'" :message="message"/>
  </div>
</template>

<script>
import NoteContent from "@/components/sreq/feed/messages/NoteContent";
import ChatMessageContent from "@/components/sreq/feed/messages/ChatMessageContent";
import HistoryMessageContent from "@/components/sreq/feed/messages/HistoryMessageContent";
import {mapGetters} from "vuex";
import {MESSAGE_TYPE_NOTE, MESSAGE_TYPE_SIMPLE, USER_TYPE_QUEXT} from "@/utils/constants/chat";

export default {
  name: "Message",
  components: {
    HistoryMessageContent,
    ChatMessageContent,
    NoteContent,
  },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  emits: [
    'clickRetry',
    'clickDelete',
  ],
  computed: {
    ...mapGetters({
      cognitoUserId: 'auth/cognitoUserId'
    }),
    isMy() {
      return this.cognitoUserId === this.message.author.id.identityId;
    },
    authorType() {
      return this.message.author.id.userType;
    }
  },
  data() {
    return {
      MESSAGE_TYPE_NOTE,
      MESSAGE_TYPE_SIMPLE,
      USER_TYPE_QUEXT
    }
  }
}
</script>

<style scoped>
.message {
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-break: break-word;
}

.message.my {
  margin-left: auto;
  align-items: flex-end;
}
</style>
