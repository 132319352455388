<template>
  <div>
    <StatusTimeTypeBlock
      :outgoing="false"
      :datetime="message.datetime"
      type="history"
    >
      <template v-slot:author>
        <span class="text-xs ml-2">{{ $t('sreq.feed.message.system') }}</span>
      </template>
    </StatusTimeTypeBlock>
    <div class="content-box p-5" :class="{cancellation: isCancellation}">
      <div v-if="triggeredBy !== 'system'" class="author text-xs mb-2" :class="{ 'text-green-700': triggeredBy === 'property-staff' }">
        <span class="name font-600">{{ $t('sreq.feed.message.triggered_by', {name: message.action.triggeredBy.fullName}) }}</span>
        <span class="role">, {{ message.action.triggeredBy.role }}</span>
      </div>
      <div class="text-sm" v-if="!isCancellation">
        {{ message.text }}
      </div>
      <div class="cancellation-text text-xs" v-else>
        <div class="font-600 mb-1">{{ $t('sreq.feed.message.cancelation_reason') }}</div>
        <div class="font-500">{{ message.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusTimeTypeBlock from "@/components/sreq/feed/messages/StatusTimeTypeBlock";
export default {
  name: "HistoryMessageContent",
  components: {StatusTimeTypeBlock},
  props: {
    message: {
      type: Object,
      required: true
    },
    byPropertyStaff: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    triggeredBy() {
      switch (this.message.action.triggeredBy.role) {
        case 'system':
        case 'resident':
          return this.message.action.triggeredBy.role;
        default:
          return 'property-staff';
      }
    },
    isCancellation() {
      return this.message.action.type === 'cancellation';
    }
  }
}
</script>

<style scoped>
.content-box {
  border: 1px solid #F2F2F2;
  display: inline-block;
}

.content-box.cancellation {
  border-color: #840000;
}

.cancellation-text {
  color: #840000;
}
</style>
