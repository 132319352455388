<template>
  <div>
    <StatusTimeTypeBlock
        :outgoing="isMy"
        :status="note.status"
        :datetime="note.timestamp"
        type="note"
        @clickRetry="$emit('clickRetry')"
        @clickDelete="$emit('clickDelete')"
    >
      <template v-slot:author>
        <span v-if="!isMy" class="author text-xs lowercase ml-2" :class="{ 'text-green-700': authorType === USER_TYPE_QUEXT }">
          <span class="name font-600">{{ note.author.nickname }}</span>
          <span class="role">, {{ note.author.role }}</span>
        </span>
      </template>
    </StatusTimeTypeBlock>
    <div class="content-box">
      <div class="head p-5 text-xs">
        <div class="font-600 mb-2">{{ $t(note.subtype === NOTE_TYPE_ORDINARY ? 'sreq.feed.message.ordinary_note' : 'sreq.feed.message.cost_allocation_note') }}</div>
        <template v-if="note.subtype === NOTE_TYPE_ORDINARY">
          <div class="col">
            <div v-if="!note.private"><CheckmarkIcon class="text-green-500 mr-2"/>{{ $t('sreq.feed.message.public') }}</div>
            <div v-else><CloseIcon class="text-red-600 mr-2"/>{{ $t('sreq.feed.message.only_internal') }}</div>
          </div>
        </template>
        <div v-else class="cost-allocation-details">
          <div class="col">
            <div v-if="note.body.chargeResident"><CheckmarkIcon class="text-green-500 mr-2"/>{{ $t('sreq.feed.message.charge_resident') }}</div>
            <div v-else><CloseIcon class="text-red-600 mr-2"/>{{ $t('sreq.feed.message.dont_charge_resident') }}</div>
            <div v-if="note.body.chargeResident">
              <CheckmarkIcon class="text-green-500 mr-2"/>{{ $t(note.body.recurring ? 'sreq.feed.message.recurring_payment' : 'sreq.feed.message.one_time_payment') }}
            </div>
          </div>
          <div class="col" v-if="note.body.recurringFromDate">
            <div class="font-600">{{ $t('sreq.feed.message.start_date') }}</div>
            <div class="">{{ prepareDateString(note.body.recurringFromDate) }}</div>
          </div>
          <div class="col" v-if="note.body.dueDate">
            <div class="font-600">{{ $t('sreq.feed.message.due_date') }}</div>
            <div class="">{{ prepareDateString(note.body.dueDate) }}</div>
          </div>
          <div class="col" v-if="note.body.totalCost">
            <div class="font-600">{{ $t('sreq.feed.message.total_cost') }}</div>
            <div class="">${{ note.body.totalCost.toLocaleString('en-US') }}</div>
          </div>
          <div class="col" v-if="note.body.recurringMonths">
            <div class="font-600">{{ $t('sreq.feed.message.number_of_months') }}</div>
            <div class="">{{ note.body.recurringMonths }}</div>
          </div>
        </div>
      </div>
      <div class="body p-5 text-sm">
        <div class="text">
          {{ note.body.text }}
        </div>
<!--        <Attachments :items="note.body.attachments"/>-->
      </div>
    </div>
  </div>
</template>

<script>
import CheckmarkIcon from "@/components/ui/icons/CheckmarkIcon";
import CloseIcon from "@/components/ui/icons/CloseIcon";
import StatusTimeTypeBlock from "@/components/sreq/feed/messages/StatusTimeTypeBlock";
// import Attachments from "@/components/ui/Attachments";
import moment from 'moment-timezone';
import {NOTE_TYPE_ORDINARY, USER_TYPE_QUEXT} from "@/utils/constants/chat";

export default {
  name: "NoteContent",
  components: {
    // Attachments,
    StatusTimeTypeBlock,
    CloseIcon,
    CheckmarkIcon
  },
  props: {
    note: {
      type: Object,
      required: true
    },
    isMy: {
      type: Boolean,
      required: true
    },
    authorType: {
      type: String,
      required: true
    }
  },
  emits: [
    'clickRetry',
    'clickDelete',
  ],
  data() {
    return {
      NOTE_TYPE_ORDINARY,
      USER_TYPE_QUEXT
    }
  },
  methods: {
    prepareDateString(isoDateString) {
      return moment(isoDateString).format('MM/DD/YYYY');
    }
  }
}
</script>

<style scoped>
.content-box {
  border: 1px solid #F2F2F2;
  background-color: #FAFAFA;
}

.content-box .head {
  border-bottom: 1px solid #F2F2F2;
}

.my .content-box {
  background-color: #F4FAFF;
  border-color: #D6ECFF;
  color: #186498;
}

.my .content-box .head {
  border-bottom: 1px solid #D6ECFF;
  color: #145480
}

.from-property .content-box {
  background-color: #EFFDFB;
  border-color: #BDF2E9;
  color: #007461;
}

.from-property .content-box .head {
  border-bottom: 1px solid #BDF2E9;
  color: #016E5C;
}

.cost-allocation-details {
  display: grid;
  grid-template-columns: auto auto auto auto;
  column-gap: 1rem;
}

svg {
  height: 1em;
  display: inline;
}
</style>
