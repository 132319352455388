<template>
  <div class="composer">
    <FinalForm ref="form" :submit="handleSubmit" :initialValues="initialValues" @change.self="handleFormChange">
      <template v-slot="props">
        <form @submit="props.handleSubmit">
          <div class="head-part px-3 py-2 text-xs flex justify-between">
            <div class="type-selector whitespace-no-wrap">
              <i18n-t keypath="sreq.feed.composer.header_text">
                <span>
                  <button
                      type="button"
                      v-for="option of messageTypeOptions"
                      :key="option.key"
                      class="inline-flex mr-1 font-600"
                      :class="{
                        'text-gray-400 hover:text-black cursor-pointer': !option.disabled,
                        'text-gray-300 cursor-not-allowed': option.disabled,
                        'text-blue-500': formValues.messageType === option.key,
                      }"
                      :disabled="option.disabled"
                      @click="setMessageType(option.key)"
                  >
                    {{option.value}}&nbsp;
                    <tooltip
                        v-if="option.disableReason"
                        icon="exclamationCircle"
                        :text="option.disableReason"
                        :arrow="false"
                        class="text-left w-3 h-3"
                    />
                  </button>
                </span>
              </i18n-t>
            </div>
            <div class="expand-collapse">
              <a @click="expanded = !expanded" class="cursor-pointer">
                <MinimizeIcon v-if="expanded"/>
                <MaximizeIcon v-else/>
              </a>
            </div>
          </div>
          <div class="body-part" :class="{'expanded': expanded}" v-if="expanded">
            <div class="note-related px-3 pt-2" v-if="formValues.messageType === MESSAGE_TYPE_NOTE">
              <div class="general flex items-center pb-2">
                <RadioGroupInput :options="noteTypeOptions" label="" name="noteType" class="note-types flex font-400 text-xs"/>
                <div class="delimeter mr-6"></div>
                <ToggleButtonsField
                    v-if="formValues.noteType === NOTE_TYPE_ORDINARY"
                    name="isPublic"
                    :options="[
                        { key: true, value: $t('sreq.feed.composer.public') },
                        { key: false, value: $t('sreq.feed.composer.internal') },
                    ]"
                />
                <CheckboxInput
                    v-if="formValues.noteType === NOTE_TYPE_COST_ALLOCATION"
                    class="ml-0"
                    :value="$t('sreq.feed.composer.charge_resident')"
                    name="chargeResident"
                    :disabled="publicCostAllocationNotesRestriction !== undefined"
                    :disable-reason="generateRestrictionLabelText(publicCostAllocationNotesRestriction)"
                />
              </div>

              <div v-if="formValues.noteType === NOTE_TYPE_COST_ALLOCATION" class="cost-allocation-specific pb-3">
                <RadioGroupInput
                    v-if="formValues.chargeResident"
                    :options="isRecurringOptions"
                    :label="$t('sreq.feed.composer.recurring_payment')"
                    name="isPaymentRecurring"
                    class="is-recurring flex flex-wrap font-400 text-xs pt-2"
                />
                <div class="payment-details mt-3">
                  <DateInput
                      v-if="formValues.chargeResident && formValues.isPaymentRecurring === 'yes'"
                      :validate="activeValidators.startDate"
                      :label="$t('sreq.feed.composer.start_date') + '*'"
                      name="startDate"
                      :disabledDates="(date) => date.getDate() !== 1"
                      :in-community-timezone="false"
                  />
                  <DateInput
                      v-if="formValues.chargeResident && formValues.isPaymentRecurring === 'no'"
                      :validate="activeValidators.dueDate"
                      :label="$t('sreq.feed.composer.due_date') + '*'"
                      name="dueDate"
                      :disabledDates="(date) => date.getDate() !== 1"
                      :in-community-timezone="false"
                  />
                  <TextField
                      key="totalCost"
                      :validate="activeValidators.totalCost"
                      :label="$t('sreq.feed.composer.total_cost') + '*'"
                      name="totalCost"
                  />
                  <TextField
                      key="numberOfMonths"
                      v-if="formValues.chargeResident && formValues.isPaymentRecurring === 'yes'"
                      :validate="activeValidators.numberOfMonths"
                      :label="$t('sreq.feed.composer.number_of_months') + '*'"
                      name="numberOfMonths"
                  />
                </div>
              </div>
            </div>

            <div class="text-and-attachments">
              <TextField
                  name="text"
                  :placeholder="$t('sreq.feed.composer.placeholder')"
                  multiline
                  class="message-body"
                  :disabled="submissionDisabled"
                  :validate="maxLength(5000)"
              />
              <div class="attachments px-4 py-3 pb-1" v-if="attachments.length > 0">
                <div class="flex flex-wrap" v-if="attachedImages">
                  <div
                      v-for="(image, index) of attachedImages"
                      :key="index"
                      class="image mr-1 mb-1"
                  >
                    <img :src="image.src.preview" :alt="image.title" :title="image.title"/>
                    <button class="delete text-white">
                      <TimesIcon/>
                    </button>
                  </div>
                </div>
                <div class="mt-1 flex flex-wrap" v-if="attachedDocuments">
                  <div
                      v-for="(doc, index) of attachedDocuments"
                      :key="index"
                      class="document text-xs py-1 px-2 pr-0 mr-1 mb-1"
                  >{{ doc.title }} <button class="delete px-2">&times;</button></div>
                </div>
              </div>
            </div>

            <div class="blue-bar flex items-center px-3 py-1">
              <a class="attach hover:text-blue-500">
                <ClipIcon/>
              </a>
              <div class="divider ml-3"></div>
              <div class="message-length ml-auto mr-2">{{ formValues.text.length }}/5000</div>
              <button
                  type="submit"
                  class="send hover:text-blue-500"
                  :disabled="submissionDisabled"
              >
                <SendIcon/>
              </button>
            </div>
          </div>
        </form>
      </template>
    </FinalForm>
  </div>
</template>

<script>
import MinimizeIcon from "@/components/ui/icons/MinimizeIcon";
import ClipIcon from "@/components/ui/icons/ClipIcon";
import SendIcon from "@/components/ui/icons/SendIcon";
import TimesIcon from "@/components/ui/icons/TimesIcon";
import {FinalForm} from 'vue-final-form';
import RadioGroupInput from "@/components/form/RadioGroupInput";
import TextField from "@/components/form/TextField";
import DateInput from "@/components/form/DateInput";
import ValidatorMixin from "@/components/form/ValidatorMixin";
import InitializeFormMixin from "@/components/form/InitializeFormMixin";
import MaximizeIcon from "@/components/ui/icons/MaximizeIcon";
import {
  MESSAGE_TYPE_NOTE,
  MESSAGE_TYPE_SIMPLE,
  NOTE_TYPE_COST_ALLOCATION,
  NOTE_TYPE_ORDINARY
} from "@/utils/constants/chat";
import CommunityTimezoneMixin from "@/mixins/CommunityTimezoneMixin";
import Tooltip from "@/components/ui/Tooltip";
import ToggleButtonsField from "@/components/form/ToggleButtonsField";
import CheckboxInput from "@/components/form/CheckboxInput";

export default {
  name: "MessageComposer",
  components: {
    CheckboxInput,
    ToggleButtonsField,
    Tooltip,
    MaximizeIcon,
    DateInput,
    TextField,
    RadioGroupInput,
    TimesIcon,
    SendIcon,
    ClipIcon,
    MinimizeIcon,
    FinalForm
  },
  mixins: [
    InitializeFormMixin,
    ValidatorMixin,
    CommunityTimezoneMixin
  ],
  props: {
    forceMessageType: {
      type: String,
      required: false,
    },
    restrictions: {
      type: Array,
      required: true,
    }
  },
  emits: [
    'messageTypeChanged',
    'messageSent',
  ],
  data() {
    return {
      MESSAGE_TYPE_NOTE,
      MESSAGE_TYPE_SIMPLE,
      NOTE_TYPE_ORDINARY,
      NOTE_TYPE_COST_ALLOCATION,
      expanded: true,
      attachments: [
        // {
        //   type: 'image',
        //   src: {
        //     preview:'https://i.ytimg.com/vi/f_uADyhDDpE/sddefault.jpg',
        //     full:'https://i.ytimg.com/vi/f_uADyhDDpE/sddefault.jpg'
        //   },
        //   title: 'leaking faucet'
        // },
        // {
        //   type: 'image',
        //   src: {
        //     preview:'https://www.lancetteagency.com/img/~www.lancetteagency.com/Broken-Window.jpg',
        //     full:'https://www.lancetteagency.com/img/~www.lancetteagency.com/Broken-Window.jpg'
        //   },
        //   title: 'broken window'
        // },
        // {
        //   type: 'document',
        //   src: 'example.com/document1',
        //   title: 'some document'
        // }
      ],
      validatorsPresets: {
        startDate: this.required,
        dueDate: this.required,
        totalCost: this.composeValidators(
            this.required,
            this.number,
            this.minValue(1),
            this.maxValue(9999999999),
            this.maxDecimalPlaces(2)
        ),
        numberOfMonths: this.composeValidators(
            this.required,
            this.number,
            this.naturalNumber,
            this.minValue(2),
            this.maxValue(30)
        ),
      },
      activeValidators: {
        startDate: null,
        dueDate: null,
        totalCost: null,
        numberOfMonths: null,
      },
      isRecurringOptions: [
        { key: 'yes', value: this.$t('app.yes') },
        { key: 'no', value: this.$t('app.no') },
      ],
      lastSentMessage: null,
      formValues: null,
    };
  },
  computed: {
    initialValues() {
      const initialType = [MESSAGE_TYPE_NOTE, MESSAGE_TYPE_SIMPLE].includes(this.forceMessageType) ?
          this.forceMessageType :
          MESSAGE_TYPE_NOTE;
      return {
        messageType: this.lastSentMessage?.messageType ?? initialType,
        noteType: this.ordinaryNotesRestriction ? NOTE_TYPE_COST_ALLOCATION : NOTE_TYPE_ORDINARY,
        text: '',
        isPublic: (this.lastSentMessage?.noteType === NOTE_TYPE_ORDINARY && this.lastSentMessage?.isPublic) ?? false,
        chargeResident: false,
        isPaymentRecurring: 'no',
        startDate: this.getNearestMonthStart(),
        dueDate: this.getNearestMonthStart(),
        totalCost: null,
        numberOfMonths: null
      }
    },
    attachedImages() {
      return this.attachments.filter(a => a.type === 'image');
    },
    attachedDocuments() {
      return this.attachments.filter(a => a.type === 'document');
    },
    messageTypeOptions() {
      const disableNotes = this.ordinaryNotesRestriction && this.restrictAllCostAllocationNotes;
      const notesRestriction = this.restrictions.find(({type}) => type === MESSAGE_TYPE_NOTE);

      return [
        {
          key: MESSAGE_TYPE_NOTE,
          value: this.$t('sreq.feed.composer.note'),
          disabled: disableNotes,
          disableReason: disableNotes ? this.generateRestrictionLabelText(notesRestriction) : null,
        },
        {
          key: MESSAGE_TYPE_SIMPLE,
          value: this.$t('sreq.feed.composer.chat'),
          disabled: this.simpleMessagesRestriction !== undefined,
          disableReason: this.generateRestrictionLabelText(this.simpleMessagesRestriction),
        },
      ];
    },
    noteTypeOptions() {
      const costAllocationRestriction = this.restrictions
          .find(({type, subtype}) => type === MESSAGE_TYPE_NOTE && subtype === NOTE_TYPE_COST_ALLOCATION);
      return [
        {
          key: NOTE_TYPE_ORDINARY,
          value: this.$t('sreq.feed.composer.ordinary'),
          disabled: this.ordinaryNotesRestriction !== undefined,
          disableReason: this.generateRestrictionLabelText(this.ordinaryNotesRestriction)
        },
        {
          key: NOTE_TYPE_COST_ALLOCATION,
          value: this.$t('sreq.feed.composer.cost_allocation'),
          disabled: this.restrictAllCostAllocationNotes,
          disableReason: this.restrictAllCostAllocationNotes ?
              this.generateRestrictionLabelText(costAllocationRestriction) :
              null
        },
      ];
    },
    simpleMessagesRestriction() {
      return this.restrictions.find(({type}) => type === MESSAGE_TYPE_SIMPLE);
    },
    ordinaryNotesRestriction() {
      return this.restrictions.find(({type, subtype}) => type === MESSAGE_TYPE_NOTE && subtype === NOTE_TYPE_ORDINARY);
    },
    publicCostAllocationNotesRestriction() {
      return this.restrictions.find(r => {
        return r.type === MESSAGE_TYPE_NOTE &&
            r.subtype === NOTE_TYPE_COST_ALLOCATION &&
            r.isPublic === true;
      });
    },
    privateCostAllocationNotesRestriction() {
      return this.restrictions.find(r => {
        return r.type === MESSAGE_TYPE_NOTE &&
            r.subtype === NOTE_TYPE_COST_ALLOCATION &&
            r.isPublic === false;
      });
    },
    restrictAllCostAllocationNotes() {
      return this.publicCostAllocationNotesRestriction !== undefined &&
          this.privateCostAllocationNotesRestriction !== undefined;
    },
    restrictEverything() {
      return this.simpleMessagesRestriction !== undefined &&
          this.ordinaryNotesRestriction !== undefined &&
          this.restrictAllCostAllocationNotes;
    },
    submissionDisabled() {
      const {messageType, noteType} = this.$refs.form?.formState?.values || this.initialValues;

      switch (messageType) {
        case MESSAGE_TYPE_NOTE:
          switch (noteType) {
            case NOTE_TYPE_ORDINARY:
              return this.ordinaryNotesRestriction !== undefined;
            case NOTE_TYPE_COST_ALLOCATION:
              return this.restrictAllCostAllocationNotes;
            default:
              return true;
          }
        case MESSAGE_TYPE_SIMPLE:
          return this.simpleMessagesRestriction !== undefined;
      }

      return true;
    },
  },
  methods: {
    handleFormChange(state) {
      this.formValues = state.values;
    },
    generateRestrictionLabelText(restriction) {
      if (!restriction) return null;

      const messageTypeKey = [
        restriction.type,
        restriction.subtype,
        restriction.isPublic && 'PUBLIC',
      ].filter(s => s).join('_');

      const messageType = this.$t(`sreq.feed.composer.restriction.type.${messageTypeKey}`);

      return this.$t(`sreq.feed.composer.restriction.${restriction.reason}`, {messageType});
    },
    setMessageType(type) {
      this.$emit('messageTypeChanged', type);
      this.$refs.form.finalForm.change('messageType', type);
    },
    handleSubmit(values) {
      if (this.restrictEverything) return;

      if (!values.text) {
        return {text: this.$t('validation.required')};
      }

      this.lastSentMessage = values;

      this.$emit('messageSent', values);

      setTimeout(this.$refs.form.finalForm.restart);
    },
    recomputeValidators(formValues) {
      const isCostAllocationNote = formValues.messageType === MESSAGE_TYPE_NOTE && formValues.noteType === NOTE_TYPE_COST_ALLOCATION;
      const isChargeResident = isCostAllocationNote && formValues.chargeResident === true;
      const isPaymentRecurring = formValues.isPaymentRecurring === 'yes';

      this.activeValidators.totalCost = isCostAllocationNote ? this.validatorsPresets.totalCost : null;
      this.activeValidators.dueDate = isChargeResident && !isPaymentRecurring ? this.validatorsPresets.dueDate : null;
      this.activeValidators.startDate = isChargeResident && isPaymentRecurring ? this.validatorsPresets.startDate : null;
      this.activeValidators.numberOfMonths = isChargeResident && isPaymentRecurring ? this.validatorsPresets.numberOfMonths : null;
    },
    getNearestMonthStart() {
      const today = this.parseDateTime(new Date());
      const dateToReturn = today.date() === 1 ? today : today.month(today.month() + 1).startOf('month');

      return dateToReturn.format('YYYY-MM-DD');
    }
  },
  watch: {
    forceMessageType(type) {
      if (this.formValues.messageType !== type && [MESSAGE_TYPE_NOTE, MESSAGE_TYPE_SIMPLE].includes(type)) {
        this.setMessageType(type);
      }
    },
  },
  created() {
    this.formValues = this.initialValues;
  },
  mounted() {
    const {finalForm} = this.$refs.form;

    this.formUnsubscribe = finalForm.subscribe((formState) => {
      this.recomputeValidators(formState.values)
    }, {values: true})
  },
  beforeUnmount() {
    this.formUnsubscribe();
  }
}
</script>

<style scoped>
.composer {
  border: 1px solid #E8E8E8;
}

.head-part {
  background-color: #F8F8F8;
  line-height: 1.15em;
}

.variants > *:first-child {
  @apply mx-1;
}

.expand-collapse svg {
  color: #2c3e50;
  height: 1.15em;
}

.restriction-label {
  @apply font-frank font-medium text-blue-700 text-xs ml-4;
}

.note-related {
  background-color: #EBEBEB;
}

.composer:deep(.form-col) {
  margin: 0;
  flex: 0 0 auto;
}

.composer:deep(.form-col.note-types label:first-child) {
  display: none;
}
.composer:deep(.form-col label) {
  @apply font-400 text-xs mb-0 mr-6;
}

.composer:deep(.form-col label input[type="radio"]) {
  @apply w-2 h-2;
}

.composer:deep(.checkbox-label input[type="checkbox"]) {
  @apply w-2 h-2;
}

.delimeter {
  height: 22px;
  border-left: 1px solid #DDDDDD;
}

.cost-allocation-specific {
  border-top: 1px solid #DDDDDD;
}

.composer:deep(.form-col.is-recurring label:first-child) {
  @apply w-full mb-2;
}

.payment-details {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
}

.payment-details:deep(input[type="text"]) {
  @apply text-xs;
  height: 23px;
}

.text-and-attachments {
  background-color: #fff;
}

.text-and-attachments:deep(textarea) {
  border: none;
  width: 100%;
  resize: none;
  margin-bottom: -5px;
}
.text-and-attachments:deep(.form-hint.form-error) {
  @apply mt-0;
}
.message-length {
  font-size: 10px;
  line-height: 1;
}

.attachments .image {
  position: relative;
}
.attachments .image img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.attachments .image .delete {
  display: none;
  position: absolute;
  width: 19px;
  height: 19px;
  top: -9px;
  right: -9px;
  border-radius: 100%;
  background-color: #8e8e8e;
  z-index: 1;
}
.image:hover .delete {
  display: flex;
  align-items: center;
  justify-content: center;
}
.image .delete svg {
  width: 70%;
}
.attachments .document {
  color: #00538D;
  background-color: #EAF6FF;
}
.document .delete {
  color: #b6d2e6;
}

.blue-bar {
  background-color: #EDF6FC;
}
.blue-bar .divider {
  height: 20px;
  border-left: 1px solid #D0E4F2;
}
</style>
