<template>
 <div>
   <loader :loading="loading && (reachedTop || reachedBottom)" :backdrop="true"/>
   <slot></slot>
 </div>
</template>

<script>
  import Loader from '@/components/ui/Loader';

  export default {
    name: "InfiniteScrollWrapper",
    components: {
      Loader,
    },
    props: {
      topThreshold: {
        type: Number,
        // 1 is important here to avoid issues with subpixel values
        default: 1
      },
      bottomThreshold: {
        type: Number,
        // 1 is important here to avoid issues with subpixel values
        default: 1
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    emits: [
      'reachedTop',
      'reachedBottom',
    ],
    data() {
      return {
        reachedTop: true,
        reachedBottom: false
      }
    },
    methods: {
      handleScroll(event) {
        if (this.loading) return;

        const topReachedNow = event.target.scrollTop <= this.topThreshold;
        if (topReachedNow && !this.reachedTop) {
          this.reachedTop = true;
          this.$emit('reachedTop');
        } else if (!topReachedNow && this.reachedTop) {
          this.reachedTop = false;
        }

        const bottomReachedNow = event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight <= this.bottomThreshold;
        if (bottomReachedNow && !this.reachedBottom) {
          this.reachedBottom = true;
          this.$emit('reachedBottom');
        } else if (!bottomReachedNow && this.reachedBottom) {
          this.reachedBottom = false;
        }
      }
    },
    mounted() {
      this.$el.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
      this.$el.removeEventListener('scroll', this.handleScroll);
    }
  }
</script>
