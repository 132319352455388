<template>
  <FinalField :name="name" :validate="validate" type="radio">
    <template v-slot="props">
      <div class="form-col">
        <label :for="name">{{ label }}</label>
        <label
            v-for="option in options"
            :key="option.key"
            :class="{'cursor-pointer': editMode, 'disabled': option.disabled}"
            class="radio-label"
        >
          <input
              v-if="editMode"
              type="radio"
              :name="props.name"
              :checked="props.value === option.key"
              :value="option.key"
              v-on="props.events"
              :disabled="option.disabled"
          />
          <template v-if="editMode || !editMode && props.value && props.value === option.key">
            <icon
                v-if="!editMode"
                class="inline"
                :name="props.value && props.value != '0'? 'ok' : 'cancel'"
            />
            {{ option.value }}
          </template>
          <tooltip
              v-if="option.disabled && option.disableReason"
              icon="exclamationCircle"
              :text="option.disableReason"
              :arrow="false"
              class="text-left w-3 h-3 ml-1"
          />
        </label>
        <span class="form-hint form-error" v-if="props.meta.error && props.meta.touched">{{ props.meta.error }}</span>
      </div>
    </template>
  </FinalField>
</template>

<script>
import {FinalField} from 'vue-final-form';
import Tooltip from "@/components/ui/Tooltip";
import Icon from '@/components/ui/Icon';

export default {
  name: 'RadioGroupInput',
  components: {
    Tooltip,
    FinalField,
    Icon,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    validate: {
      type: [Function, Array],
      required: false,
    },
    editMode: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.form-col label.radio-label {
  font: inherit;
  padding-left: 1px;
}

.disabled {
  @apply text-gray-300;
}

.form-col:deep(.tooltip-wrapper) {
  vertical-align: -4px;
}
</style>
