<template>
  <div>
    <StatusTimeTypeBlock
        :outgoing="isMy"
        :status="message.status"
        :datetime="message.timestamp"
        type="chat"
        @clickRetry="$emit('clickRetry')"
        @clickDelete="$emit('clickDelete')"
    >
      <template v-slot:author>
        <span v-if="!isMy" class="author text-xs lowercase ml-2" :class="{ 'text-green-700': authorType === USER_TYPE_QUEXT }">
          <span class="name font-600">{{ message.author.nickname }}</span>
          <span class="role">, {{ message.author.role }}</span>
        </span>
      </template>
    </StatusTimeTypeBlock>
    <div class="content-box p-5 text-sm">
      <div class="text">
        {{ message.body.text }}
      </div>
<!--      <Attachments :items="message.body.attachments"/>-->
    </div>
  </div>
</template>

<script>
import StatusTimeTypeBlock from "@/components/sreq/feed/messages/StatusTimeTypeBlock";
import {USER_TYPE_QUEXT} from "@/utils/constants/chat";
// import Attachments from "@/components/ui/Attachments";

export default {
  name: "ChatMessageContent",
  components: {/*Attachments, */StatusTimeTypeBlock},
  props: {
    message: {
      type: Object,
      required: true
    },
    isMy: {
      type: Boolean,
      required: true
    },
    authorType: {
      type: String,
      required: true
    }
  },
  emits: [
    'clickRetry',
    'clickDelete',
  ],
  data() {
    return {
      USER_TYPE_QUEXT
    }
  }
}
</script>

<style scoped>
.content-box {
  border: 1px solid #F2F2F2;
  background-color: #FAFAFA;
}

.my .content-box {
  background-color: #F4FAFF;
  border-color: #D6ECFF;
  color: #186498;
}

.from-property .content-box {
  background-color: #EFFDFB;
  border-color: #BDF2E9;
  color: #007461;
}
</style>
