<template>
  <div class="flex flex-col h-full overflow-hidden">
    <FiltersBlock :filters="filters" @change="handleFiltersChange" class="pb-4"/>

    <InfiniteScrollWrapper
        ref="messagesBlock"
        class="overflow-y-auto flex-grow"
        @reachedTop="loadPreviousMessages"
        :loading="loadingPrevious"
    >
      <div class="messages-block">
        <div
            v-for="(message, index) in messages"
            :key="message.id"
            :id="`message-${message.id}`"
            :class="{ 'bg-blue-50': isUnread(index) }"
        >
          <div
              v-if="message.id === firstUnreadId"
              class="bg-blue-50 text-gray-400 text-sm py-4 flex justify-center items-center"
          >
            {{ $t('sreq.feed.unread_messages') }}
          </div>
          <div v-if="index === 0 || messages[index - 1].day !== message.day" class="day-denoter py-6">
            <span class="day-text text-xs lowercase px-4 ml-6">
              {{ message.day }}
              <Tooltip icon="clock" :text="$t('sreq.feed.timezone_warning')"/>
            </span>
            <hr>
          </div>
          <Message
              :message="message"
              @clickRetry="retryMessageSending(message)"
              @clickDelete="deleteFailedMessage(message)"
          />
        </div>

        <button
            v-if="showGoBottom"
            class="go-bottom flex items-center justify-center"
            @click="scrollToBottom"
        ><ChevronDownIcon/></button>
      </div>
    </InfiniteScrollWrapper>

    <MessageComposer
        :force-message-type="filters.type"
        :restrictions="messagingRestrictions"
        @messageTypeChanged="handleMessageTypeChanged"
        @messageSent="handleMessageSubmission"
    />
  </div>
</template>

<script>
import FiltersBlock from "@/components/sreq/feed/FiltersBlock";
import {mapActions, mapGetters} from "vuex";
import MessageComposer from "@/components/sreq/feed/MessageComposer";
import CommunityTimezoneMixin from "@/mixins/CommunityTimezoneMixin";
import moment from 'moment-timezone';
import {
  CHAT_ACTION_WRITE,
  MESSAGE_STATUS_FAILED,
  MESSAGE_STATUS_PENDING,
  MESSAGE_STATUS_RECEIVED,
  MESSAGE_TYPE_NOTE,
  MESSAGE_TYPE_SIMPLE,
  NOTE_TYPE_COST_ALLOCATION,
  NOTE_TYPE_ORDINARY,
  USER_TYPE_QUEXT
} from "@/utils/constants/chat";
import EventBus from "@/utils/EventBus";
import InfiniteScrollWrapper from "@/components/list/InfiniteScrollWrapper";
import Tooltip from "@/components/ui/Tooltip";
import Message from "@/components/sreq/feed/Message";
import ChevronDownIcon from "@/components/ui/icons/ChevronDownIcon";

export default {
  name: "Messenger",
  components: {ChevronDownIcon, Message, Tooltip, InfiniteScrollWrapper, MessageComposer, FiltersBlock},
  mixins: [CommunityTimezoneMixin],
  props: {
    chatId: {
      type: String,
      required: true,
    },
    initialTypeFilter: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      filters: {
        type: null,
        searchQuery: '',
        notesFilters: [],
      },
      messages: [],
      loadingPrevious: false,
      hasPrevious: true,
      messagingRestrictions: [],
      showGoBottom: false,
      firstUnreadId: null,
    };
  },
  computed: {
    ...mapGetters({
      profile: 'auth/profile',
      cognitoId: 'auth/cognitoUserId'
    }),
    firstUnreadIndex() {
      if (!this.firstUnreadId) {
        return -1;
      }

      return this.messages.findIndex((message) => message.id === this.firstUnreadId);
    },
  },
  methods: {
    ...mapActions({
      resetTmpBuffer: 'sreq/resetTmpBuffer',
    }),

    prepareDayString(date) {
      return moment(date).format('dddd, MM/DD/YYYY');
    },
    prepareMessage(message) {
      const timestamp = this.parseDateTime(message.timestamp);
      return {
        ...message,
        status: MESSAGE_STATUS_RECEIVED,
        timestamp,
        day: this.prepareDayString(timestamp),
      };
    },
    isUnread(index) {
      return this.firstUnreadIndex >= 0 && index >= this.firstUnreadIndex;
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const el = this.$refs.messagesBlock.$el;
        el.scrollTop = el.scrollHeight;
      });
    },
    scrollToMessage(id, smooth = false) {
      const messageElement = document.getElementById(`message-${id}`);
      messageElement.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto' });
    },
    handleFiltersChange(filters) {
      this.hasPrevious = filters.type !== this.filters.type;
      this.filters = filters;
      this.initializeMessages();
    },
    handleMessageTypeChanged(type) {
      if (this.filters.type !== type && [MESSAGE_TYPE_NOTE, MESSAGE_TYPE_SIMPLE].includes(this.filters.type)) {
        this.handleFiltersChange({
          ...this.filters,
          type,
        });
      }
    },
    async initializeMessages() {
      const { data: messages } = await this.loadMessages(true);

      this.$nextTick(() => {
        const id = this.firstUnreadId || messages?.[0]?.id;
        if (id) {
          this.scrollToMessage(id);
        }
      });
    },
    async loadMessages(initial) {
      let options;

      if (!initial) {
        options = {
          startFrom: this.messages[0].id,
        };
      }

      const response = await this.$chatDataProvider.getList('messages', {
        id: this.chatId,
        ...options,
        ...this.filters,
      });

      if (initial) {
        this.messages = [];
      }

      this.firstUnreadId = response.headers['x-quext-chat-1st-unread-msg-id'];

      const messages = response.data;
      this.hasPrevious = messages.length > 0;
      this.insertMessages(messages.map((message) => this.prepareMessage(message)).reverse());

      if (initial) {
        this.updateLastReads();
      }

      return response;
    },
    insertMessages(messages) {
      this.messages = [...messages, ...this.messages].sort((a, b) => a.timestamp - b.timestamp);
    },
    async loadPreviousMessages() {
      if (!this.hasPrevious) return;

      this.loadingPrevious = true;

      const messagesSectionElement = this.$refs.messagesBlock.$el;
      const oldScrollHeight = messagesSectionElement.scrollHeight;

      await this.loadMessages(false);

      this.$nextTick(() => {
        messagesSectionElement.scrollTop = messagesSectionElement.scrollHeight - oldScrollHeight;
        this.loadingPrevious = false;
      });
    },
    handleMessageSubmission(formValues) {
      this.firstUnreadId = null;

      const tempMessage = {
        authorId: this.profile.profileId,
        type: formValues.messageType,
        subtype: null,
        private: false,
        body: {
          text: formValues.text
        }
      };
      if (tempMessage.type === MESSAGE_TYPE_NOTE) {
        tempMessage.subtype = formValues.noteType;

        if (formValues.noteType === NOTE_TYPE_ORDINARY) {
          tempMessage.private = !formValues.isPublic;
        } else {
          tempMessage.body.totalCost = Number.parseFloat(formValues.totalCost);
          if (formValues.chargeResident) {
            tempMessage.body.chargeResident = true;
            tempMessage.body.recurring = formValues.isPaymentRecurring === 'yes';
            if (tempMessage.body.recurring) {
              tempMessage.body.recurringFromDate = moment(formValues.startDate).format('YYYY-MM-DD');
              tempMessage.body.recurringMonths = formValues.numberOfMonths;
            } else {
              tempMessage.body.dueDate = moment(formValues.dueDate).format('YYYY-MM-DD');
            }
          }
        }
      }

      this.sendMessage(tempMessage);

      if (this.filters.type && tempMessage.type !== this.filters.type) return;

      tempMessage.id = 'temp' + Math.random().toString(36).slice(2);
      tempMessage.status = MESSAGE_STATUS_PENDING;
      tempMessage.timestamp = this.parseDateTime(new Date());
      tempMessage.day = this.prepareDayString(tempMessage.timestamp);
      tempMessage.author = {id: {identityId: this.cognitoId, userType: USER_TYPE_QUEXT}};
      this.insertMessages([tempMessage]);
      this.$nextTick(() => {
        this.scrollToMessage(tempMessage.id);
      });
    },
    sendMessage(message) {
      this.$chatDataProvider.create('messages', {
        id: this.chatId,
        data: message
      }).then(({data: response}) => {
        this.messages[this.messages.findIndex(({id}) => id === message.id)] = this.prepareMessage(response);
      }).catch(() => {
        this.messages[this.messages.findIndex(({id}) => id === message.id)] = {...message, status: MESSAGE_STATUS_FAILED};
      });
    },
    retryMessageSending(message) {
      this.sendMessage(message);
    },
    deleteFailedMessage(message) {
      this.messages = this.messages.filter((m) => m !== message);
    },
    processNotifications({channel, body}) {
      switch (channel) {
        case 'CHAT/new_chat_message':
          // to different chat
          if (body.chatId !== this.chatId) return;
          // my message (already displayed)
          if (body.author.id.identityId === this.cognitoId) return;
          // message type differs from selected tab (e.g. note/chat)
          if (this.filters.type && body.type !== this.filters.type) return;

          this.insertMessages([this.prepareMessage(body)]);
          if (!this.firstUnreadId) {
            this.firstUnreadId = body.id;
          }

          this.$nextTick(() => {
            this.scrollToMessage(body.id);
          });
      }
    },
    handleMessagesBlockScroll(event) {
      this.showGoBottom = event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight > 150;
    },
    updateLastReads() {
      const lastRead = this.messages.reduceRight((acc, message) => {
        acc[message.type] ??= message.id;

        return acc;
      }, {});

      this.$chatDataProvider.lastRead('messages', {
        id: this.chatId,
        data: lastRead,
      }).then(() => {
        this.resetTmpBuffer();
      });
    },
  },
  mounted() {
    this.filters.type = this.initialTypeFilter;

    this.$chatDataProvider.getOne('chats', {id: this.chatId}).then(({data}) => {
      const {permissions} = data;
      const initialRestrictions = [
        {isPublic: true, type: MESSAGE_TYPE_NOTE, subtype: NOTE_TYPE_ORDINARY, reason: 'STATUS'},
        {isPublic: false, type: MESSAGE_TYPE_NOTE, subtype: NOTE_TYPE_COST_ALLOCATION, reason: 'STATUS'},
        {isPublic: true, type: MESSAGE_TYPE_NOTE, subtype: NOTE_TYPE_COST_ALLOCATION, reason: 'NO_RESIDENT'},
        {isPublic: true, type: MESSAGE_TYPE_SIMPLE, subtype: null, reason: 'STATUS'},
      ];
      this.messagingRestrictions = initialRestrictions.filter(({isPublic, type, subtype}) => {
        const targetStr = `${isPublic ? 'public' : 'private'}/${type}/${subtype || '*'}`;
        return !permissions.find(({target, action, allowed}) =>
            target === targetStr && action === CHAT_ACTION_WRITE && allowed);
      });
    });

    this.initializeMessages();

    this.$refs.messagesBlock.$el.addEventListener('scroll', this.handleMessagesBlockScroll);
    EventBus.on('wst-notification', this.processNotifications);
  },
  beforeUnmount() {
    this.updateLastReads();

    this.$refs.messagesBlock.$el.removeEventListener('scroll', this.handleMessagesBlockScroll);
    EventBus.off('wst-notification', this.processNotifications);
  },
}
</script>

<style scoped>
.day-denoter {
  position: relative;
}

.day-denoter hr {
  border-color: #D6ECFF;
}

.day-denoter .day-text {
  position: absolute;
  top: 28%;
  color: #00426F;
  background-color: #fff;
}

.day-text:deep(.tooltip-wrapper) {
  vertical-align: -3px;
}

.go-bottom {
  @apply ml-auto mr-2;
  position: sticky;
  bottom: 1rem;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  box-shadow: 0px 3px 4px #0000001A;
  background-color: #fff;
}
.go-bottom:deep(svg) {
  width: 70%;
  color: #ADABAB;
}
</style>
