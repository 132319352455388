<template>
  <div class="status-time-type flex items-center justify-end text-sm mb-3" :class="{'flex-row-reverse': !outgoing}">
    <span v-if="outgoing" class="delivery-status">
      <CheckmarkIcon v-if="status === MESSAGE_STATUS_RECEIVED"/>
      <PendingIcon v-else-if="status === MESSAGE_STATUS_PENDING" class="rotating"/>
      <DoubleCheckmarkIcon v-else-if="status === 'read'" class="text-blue-500"/>
      <template v-else-if="status === MESSAGE_STATUS_FAILED">
        <DropMenu direction="right" min-width="100px" ref="dropMenu">
          <template v-slot:header>
            <a>
              <DeliveryFailedIcon/>
            </a>
          </template>
          <template v-slot:default>
            <ul class="drop-menu-items">
              <li class="item cursor-pointer px-4 py-2" @click="handleDropdownItemClick('clickRetry')">
                {{ $t('sreq.feed.message.retry_action') }}
              </li>
              <li class="item cursor-pointer px-4 py-2" @click="handleDropdownItemClick('clickDelete')">
                {{ $t('sreq.feed.message.delete_action') }}
              </li>
            </ul>
          </template>
        </DropMenu>
      </template>
    </span>

    <CommunityDateTimeDisplay class="time text-xs ml-4 mr-2" :input="datetime" format="h:mm a"/>

    <slot name="author"></slot>

    <span class="type-icon">
      <BookmarkIcon v-if="type === 'note'"/>
      <MessageIcon v-else-if="type === 'chat'"/>
      <FileTextIcon v-else-if="type === 'history'"/>
    </span>
  </div>
</template>

<script>
import CheckmarkIcon from "@/components/ui/icons/CheckmarkIcon";
import DoubleCheckmarkIcon from "@/components/ui/icons/DoubleCheckmarkIcon";
import PendingIcon from "@/components/ui/icons/PendingIcon";
import DeliveryFailedIcon from "@/components/ui/icons/DeliveryFailedIcon";
import CommunityDateTimeDisplay from "@/components/ui/CommunityDateTimeDisplay";
import BookmarkIcon from "@/components/ui/icons/BookmarkIcon";
import MessageIcon from "@/components/ui/icons/MessageIcon";
import FileTextIcon from "@/components/ui/icons/FileTextIcon";
import DropMenu from "@/components/ui/DropMenu";
import {
  MESSAGE_STATUS_FAILED,
  MESSAGE_STATUS_PENDING,
  MESSAGE_STATUS_RECEIVED
} from "@/utils/constants/chat";

export default {
  name: "StatusTimeTypeBlock",
  components: {
    DropMenu,
    FileTextIcon,
    MessageIcon,
    BookmarkIcon,
    CommunityDateTimeDisplay, DeliveryFailedIcon, PendingIcon, DoubleCheckmarkIcon, CheckmarkIcon
  },
  props: {
    outgoing: {
      type: Boolean,
      required: true
    },
    status: {
      type: String,
      required: false
    },
    datetime: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      MESSAGE_STATUS_RECEIVED,
      MESSAGE_STATUS_PENDING,
      MESSAGE_STATUS_FAILED,
    };
  },
  methods: {
    handleDropdownItemClick(action) {
      this.$emit(action);
      this.$refs.dropMenu.close();
    }
  }
}
</script>

<style scoped>
.message .status-time-type svg {
  height: 1em;
}

.time {
  color: #848484;
}

.type-icon {
  color: #DFDFDF;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating {
  animation-name: rotation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.drop-menu-items .item:hover {
  background-color: #F2F9FC;
}

.delivery-status:deep(nav.drop-menu) {
  padding: 0 !important;
  top: 1.5em;
  border-radius: 0;
}
</style>
