<template>
  <div
    class="toggle-buttons"
    :class="[
      size === 'large' ? 'toggle-buttons--size-l' : 'toggle-buttons--size-s',
      {'toggle-buttons--disabled': disabled}
    ]"
  >
    <button
        type="button"
        class="toggle-buttons__button"
        v-for="option in options"
        :key="option.key"
        @click="() => {
          /**
          * Emitted on value change
          */
          $emit('change', option.key)
        }"
        :class="[value === option.key ? 'toggle-buttons__button--active' : '', buttonClass]"
        :disabled="disabled"
    >
      <Icon v-if="withIcons" :name="option.value"/>
      <span v-else>{{ option.value }}</span>
    </button>
  </div>
</template>

<script>
import Icon from "@/components/ui/Icon";

export default {
  components: {Icon},

  props: {
    /**
     * ToggleButton value
     */
    value: {
      type: String,
      required: false,
    },
    /**
     * Array of available options
     */
    options: {
      type: Array,
      required: true,
    },
    /**
     * Component size `small | large`
     */
    size: {
      type: String,
      default: 'small',
      validation: function(value) {
        return ['small', 'large'].indexOf(value) !== -1;
      },
    },
    /**
     * Should the control be disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Custom class added to button element
     */
    buttonClass: {
      type: String,
      default: 'px-3',
    },
    /**
     * Interpret `value` as name of icon
     */
    withIcons: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['change'],
}
</script>

<style scoped>
.toggle-buttons {
  @apply flex;
}

.toggle-buttons__button {
  @apply h-6 font-normal text-xs bg-active-100 border border-active-200 text-highlight-400;

  &:hover {
    @apply border-highlight-600 text-highlight-600;
  }
}

.toggle-buttons__button--active {
  @apply text-blue-500 border-blue-500;
}

.toggle-buttons--size-l {
  .toggle-buttons__button {
    @apply h-10 font-medium text-2sm;
  }
}

.toggle-buttons--disabled {
  .toggle-buttons__button {
    @apply text-gray-500 border-gray-200 bg-gray-100;
  }

  .toggle-buttons__button--active {
    @apply bg-active-100 text-blue-500 border-blue-500;
  }
}
</style>
