<template>
  <div class="filters-section flex justify-between items-baseline flex-wrap text-sm">
    <div class="tabs font-600 flex">
      <div class="tab" :class="{'active': filters.type === null}" @click="changeType(null)">
        {{ $t('sreq.feed.filter.all') }}
      </div>
      <div class="tab" :class="{'active': filters.type === MESSAGE_TYPE_SIMPLE}" @click="changeType(MESSAGE_TYPE_SIMPLE)">
        <MessageIcon/> {{ $t('sreq.feed.filter.chat') }}
      </div>
      <div class="tab" :class="{'active': filters.type === MESSAGE_TYPE_NOTE}" @click="changeType(MESSAGE_TYPE_NOTE)">
        <BookmarkIcon/> {{ $t('sreq.feed.filter.notes') }}
      </div>
<!--      <div class="tab" :class="{'active': filters.type === 'history'}" @click="changeType('history')">-->
<!--        <FileTextIcon/> {{ $t('sreq.feed.filter.history') }}-->
<!--      </div>-->
    </div>
<!--    <div class="search flex items-center">-->
<!--      <input type="text" placeholder="search" class="px-4 py-3" @input="debounceHandleSearchInput">-->
<!--      <DropMenu v-if="filters.type === MESSAGE_TYPE_NOTE" direction="right">-->
<!--        <template v-slot:header>-->
<!--          <button-->
<!--              type="button"-->
<!--              class="filter-button m-2"-->
<!--          ><FilterIcon/></button>-->
<!--        </template>-->
<!--        <template>-->
<!--          <div class="additional-filters p-4">-->
<!--            <div class="popover-arrow"></div>-->
<!--            <label v-for="filter in notesFilters" :key="filter.value" class="text-xs">-->
<!--              <input type="checkbox" :checked="filters.notesFilters.includes(filter.value)" @change="toggleNotesFilterItem(filter.value)">-->
<!--              {{ filter.text }}-->
<!--            </label>-->
<!--          </div>-->
<!--        </template>-->
<!--      </DropMenu>-->
<!--    </div>-->
    <div v-if="filters.notesFilters.length > 0" class="filter-tags w-full flex flex-wrap mt-3">
      <button v-for="value in filters.notesFilters" :key="value" @click="removeNotesFilterItem(value)" class="tag text-xs px-3 py-2 mr-2 mb-2">
        {{ getNoteFilterText(value) }}
        <span class="cancel-filter-item">&times;</span>
      </button>
      <button
        v-if="filters.notesFilters.length > 1"
        class="clear-all text-xs px-3 py-2 mb-2 text-blue-500"
        @click="clearAllNotesFilters"
      >{{ $t('sreq.feed.filter.clear_all_action') }}</button>
    </div>
  </div>
</template>

<script>
import {debounce, xor} from "lodash-es";
import MessageIcon from "@/components/ui/icons/MessageIcon";
import BookmarkIcon from "@/components/ui/icons/BookmarkIcon";
// import FileTextIcon from "@/components/ui/icons/FileTextIcon";
// import FilterIcon from "@/components/ui/icons/FilterIcon";
// import DropMenu from "@/components/ui/DropMenu";
import {MESSAGE_TYPE_NOTE, MESSAGE_TYPE_SIMPLE} from "@/utils/constants/chat";

export default {
  name: "FiltersBlock",

  components: {
    // DropMenu,
    // FilterIcon,
    // FileTextIcon,
    BookmarkIcon,
    MessageIcon,
  },

  props: {
    filters: {
      type: Object,
      required: true,
    },
  },

  emits: [
    'change',
  ],

  data() {
    return {
      MESSAGE_TYPE_NOTE,
      MESSAGE_TYPE_SIMPLE,
      showNotesFilters: false,
      notesFilters: [
        { value: 'ordinary-internal', text: this.$t('sreq.feed.filter.note_ordinary_internal') },
        { value: 'ordinary-public', text: this.$t('sreq.feed.filter.note_ordinary_public') },
        { value: 'cost-internal', text: this.$t('sreq.feed.filter.note_cost_internal') },
        { value: 'cost-public', text: this.$t('sreq.feed.filter.note_cost_public') }
      ],
      debounceHandleSearchInput: () => {},
    };
  },

  methods: {
    changeType(type) {
      this.$emit('change', {
        ...this.filters,
        type,
        notesFilters: type === MESSAGE_TYPE_NOTE ? this.filters.notesFilters : [],
      });
    },
    handleSearchInput(event) {
      this.$emit('change', {
        ...this.filters,
        searchQuery: event.target.value,
      });
    },
    toggleNotesFilterItem(value) {
      this.$emit('change', {
        ...this.filters,
        notesFilters: xor(this.filters.notesFilters, [value]),
      });
    },
    removeNotesFilterItem(value) {
      this.$emit('change', {
        ...this.filters,
        notesFilters: this.filters.notesFilters.filter(v => v !== value),
      });
    },
    clearAllNotesFilters() {
      this.$emit('change', {
        ...this.filters,
        notesFilters: [],
      });
    },
    getNoteFilterText(value) {
      return this.notesFilters.find(item => item.value === value)?.text;
    }
  },

  created() {
    this.debounceHandleSearchInput = debounce(this.handleSearchInput, 500);
  }
}
</script>

<style scoped>
.tabs {
  margin-left: -1rem;
  margin-right: -1rem;
}

.tab {
  @apply px-4 py-3 cursor-pointer;
  white-space: nowrap;
}

.tab:not(.active) svg {
  color: #CECECE;
}

.tab svg {
  @apply mr-2;
  display: inline;
  height: 1em;
}

.tab.active {
  @apply text-blue-500;
  border-bottom: 2px solid #3498DB;
}

.search input {
  /*width: 330px;*/
  border: 1px solid #CECECE;
}

.filter-button {
  width: 24px;
  height: 24px;
}

.filter-button svg {
  color: #2C3E50;
  height: 1rem;
  margin: auto;
}
.filter-button:hover svg {
  color: #3498DB;
}

.search {
  position: relative;
}

.search:deep(nav.drop-menu) {
  padding: 0 !important;
  top: 3.5em;
  border: none;
  overflow: visible;
}

.additional-filters {
  background-color: #F8FCFF;
  border: 1px solid #EAF6FF;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: auto auto;
  column-gap: 1rem;
}

.additional-filters .popover-arrow {
  position: absolute;
  top: 0;
  right: 10px;
  height: 10px;
  width: 10px;
  background-color: #F8FCFF;
  border-left: 1px solid #EAF6FF;
  border-top: 1px solid #EAF6FF;
  transform: translate(-50%,-50%) rotate(45deg);
}

.additional-filters label {
  white-space: nowrap;
}

.additional-filters input[type="checkbox"] {
  width: 8px;
  height: 8px;
}

.tag {
  color: #00538D;
  background-color: #EAF6FF;
}

.cancel-filter-item {
  color: #b6d2e6;
}
</style>
